import * as React from "react";
import { Box, IconButton, InputAdornment, TextField, TextareaAutosize, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import * as styles from './styles.module.css';

export default function ChatBox({ profilePic, username }) {
    const [message, setMessage] = React.useState('');

    return (
        <Box sx={{
            padding: { lg: '0 20px' },
            height: 'calc(100% - 100px)',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
            }}>
                <img src={profilePic} alt='profile-pic' width={80} height={80} style={{
                    objectFit: 'cover',
                    borderRadius: '50%',
                }} />
                <Typography variant="h5">{username}</Typography>
            </Box>

            <Box className={styles.container} sx={{
                backgroundColor: 'white',
                borderRadius: 5,
                marginTop: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <Box className={styles.container} sx={{
                    width: '100%',
                    overflowY: 'scroll',
                    height: '90%',
                    padding: 5
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                    }}>
                        <img src={profilePic} alt="profile-pic" width={50} height={50} style={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }} />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            maxWidth: '50%',
                        }}>
                            <Box sx={{
                                backgroundColor: '#f0f2f5',
                                padding: 2,
                                borderRadius: 5
                            }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                    Hello there, my name is Name,
                                    hello my friend,
                                    are you here?
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                06:32
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            maxWidth: '50%',
                        }}>
                            <Box sx={{
                                backgroundColor: '#f28646',
                                padding: 2,
                                borderRadius: 5
                            }}>
                                <Typography sx={{ textAlign: 'right', color: 'white' }}>
                                    Hello there, my name is Name
                                    here is my second name
                                    and my third name and we continue
                                    like this until the end
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                06:32
                            </Typography>
                        </Box>
                        <img src={profilePic} alt="profile-pic" width={50} height={50} style={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }} />
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                    }}>
                        <img src={profilePic} alt="profile-pic" width={50} height={50} style={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }} />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            maxWidth: '50%',
                        }}>
                            <Box sx={{
                                backgroundColor: '#f0f2f5',
                                padding: 2,
                                borderRadius: 5
                            }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                    Hello there, my name is Name,
                                    hello my friend,
                                    are you here?
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                06:32
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            maxWidth: '50%',
                        }}>
                            <Box sx={{
                                backgroundColor: '#f28646',
                                padding: 2,
                                borderRadius: 5
                            }}>
                                <Typography sx={{ textAlign: 'right', color: 'white' }}>
                                    Hello there, my name is Name
                                    here is my second name
                                    and my third name and we continue
                                    like this until the end
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                06:32
                            </Typography>
                        </Box>
                        <img src={profilePic} alt="profile-pic" width={50} height={50} style={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }} />
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                    }}>
                        <img src={profilePic} alt="profile-pic" width={50} height={50} style={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }} />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            maxWidth: '50%',
                        }}>
                            <Box sx={{
                                backgroundColor: '#f0f2f5',
                                padding: 2,
                                borderRadius: 5
                            }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                    Hello there, my name is Name,
                                    hello my friend,
                                    are you here?
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                06:32
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                    }}>
                        <img src={profilePic} alt="profile-pic" width={50} height={50} style={{
                            objectFit: 'cover',
                            borderRadius: '50%',
                        }} />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            maxWidth: '50%',
                        }}>
                            <Box sx={{
                                backgroundColor: '#f0f2f5',
                                padding: 2,
                                borderRadius: 5
                            }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                    Hello there, my name is Name,
                                    hello my friend,
                                    are you here?
                                </Typography>
                            </Box>
                            <Typography variant="caption">
                                06:32
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    border: '1px solid #d6dee1',
                    borderRadius: 5,
                    width: '100%',
                }}>
                    <Box sx={{
                        width: '95%',
                        padding: 2,
                        borderRadius: 5,
                        maxHeight: '200px',
                        // overflowY: 'scroll',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <TextareaAutosize
                            className={styles.container}
                            placeholder='Aa'
                            autoFocus
                            maxRows={5}
                            value={message}
                            style={{
                                width: '100%',
                                resize: 'none',
                                outline: 'none',
                                border: 0,
                                fontSize: '1rem',
                            }}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Box>
                    <IconButton sx={{ margin: 'auto' }}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box >
    )
};