import * as React from "react";
import { Box, Typography } from "@mui/material";

export default function MessageTemplate({
    username,
    profilePic,
    message,
    time,
    messagesNumber,
}) {
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'white',
            padding: 1,
            borderRadius: 2,
            marginBottom: 1,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#e4e6e9',
            }
        }}>
            <Box>
                <img
                    src={profilePic}
                    alt='profile-pic'
                    width={50}
                    height={50}
                    style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{username}</Typography>
                <Typography variant="body2">{message}</Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', marginLeft: 'auto', alignItems: 'flex-end' }}>
                <Typography variant="body2">{time}</Typography>
                <Typography variant="caption" sx={{
                    borderRadius: '50%',
                    backgroundColor: '#f28646',
                    color: 'white',
                    padding: '2px 10px',
                }}>{messagesNumber}</Typography>
            </Box>
        </Box >
    )
}