import * as React from "react";
import MainLayout from "../components/NavigationBar/MainLayout";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import MessageTemplate from "../components/Chat/MessageTemplate";
import Search from "@mui/icons-material/Search";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { navigate } from "gatsby";
import cld from "../services/getCloudinaryImages";
import ChatBox from "../components/Chat/ChatBox";
import useScreenResolution from "../components/hooks/useScreenResolution";
import * as styles from '../components/Chat/styles.module.css';

export default function Chat() {
    const logo = cld.image('main page photos/Rent_A_Hand_D1_dwb3is').toURL();
    const isMobile = useScreenResolution('lg');

    return (
        <MainLayout>
            <Grid container sx={{ height: 'calc(100vh - 70px)', marginTop: { xs: '64px', lg: '70px' } }}>
                <Grid item xs={12} lg={3} sx={{
                    padding: { lg: '0 16px' },
                    height: '100%',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        margin: '16px 0',
                    }}>
                        <IconButton onClick={() => navigate('/home')}><ArrowBackIcon /></IconButton>
                        <img src={logo} height={20} alt="logo img" />
                        <Typography variant="h6" sx={{ fontWeight: '700' }}>Чат</Typography>
                        <IconButton><Search /></IconButton>
                    </Box>

                    <Box className={styles.container} sx={{
                        overflowY: 'scroll',
                        height: 'calc(100% - 80px)',
                        padding: 1
                    }}>
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
                                return (
                                    <MessageTemplate
                                        key={item}
                                        username={'Name'}
                                        profilePic={'https://res.cloudinary.com/jobsportal/image/upload/v1659178189/main%20page%20photos/main_page_freelancer_suf7mw.jpg'}
                                        message={'The message will be here'}
                                        time={'06:43'}
                                        messagesNumber={'1'}
                                    />
                                )
                            })
                        }
                    </Box>
                </Grid>

                {
                    !isMobile && <Grid item xs={0} lg={9} sx={{ height: '100%' }}>
                        <ChatBox
                            username={'Name'}
                            profilePic={'https://res.cloudinary.com/jobsportal/image/upload/v1659178189/main%20page%20photos/main_page_freelancer_suf7mw.jpg'}
                        />
                    </Grid>
                }
            </Grid>
        </MainLayout>
    )
}